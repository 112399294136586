@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

.homepage-logo {
  max-width: 400px;
  margin-bottom: 10px;
}

.tag-line {
  font-size: 64px;
  font-family: "DM Serif Display", serif;
  font-weight: 300;
  text-align: center;
}

.homepage-intro {
  max-width: 600px;
  margin: 20px;
}

.services {
  max-width: 300px;
}

