nav {
  padding: 40px 30px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.logo {
    margin-right: auto;
    max-width: 30px;
}


li {
  margin-left: 20px;
}

a {
  color: #07391e;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
