@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

.heading {
  font-size: 80px;
  font-family: "DM Serif Display", serif;
  font-weight: 300;
  text-align: center;
}

.body {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.line-break {
  width: 75%;
  margin: 20px auto;
}

.btn {
  border: 2px solid;
  border-radius: 20px;
}

.btn:hover {
  background: #07391e;
  color: #FFFCEB;
}

.contact-img {
max-width: 50%;
position: relative;
object-fit: cover;
 height: auto;
 left: 200px;
}



.contact-heading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

}
.contact-heading-1 {
  top: 200px;
  width: 100%;
  font-size: 80px;
}