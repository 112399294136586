@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
.about-heading {
  text-align: left !important ;
  margin-left: 150px;
}

.about-paragraph {
  text-align: justify;
}

p {
  font-family: "Montserrat", sans-serif;
}
.our-heading {
  font-size: 48px;
}

.supported-heading {
  font-size: 36px;
}

.SH-definition {
  font-size: 28px;
}

.our-para {
  margin: 0px 50px;
}

.our-approach {
  font-family: "Montserrat", sans-serif;
}

.abouts-page {
  max-width: 75%;
}

.about-break {
  margin-top: 40px;
  max-width: 50%;
}
